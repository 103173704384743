import "./App.css";

function App() {
  const curr_date = new Date();
  const is_8_sept = curr_date.getDate() === 8 && curr_date.getMonth() + 1 === 9;

  return (
    <div className="App">
      <header className="App-header">
        <h2>Is it Hana's birthday yet?</h2>
        <h1 className={(is_8_sept ? "yes-it-is" : "no-it-is") + " indicator"}>
          {is_8_sept ? "Yep!" : "Nah"}
        </h1>
      </header>
    </div>
  );
}

export default App;
